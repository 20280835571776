<template>
<div id="performanceRank" class="performanceRank common-block">
  <div class="common-title-n">
    <div class="common-title-l">
      <img src="@/images/performanceRank_icon.png"/>
      <div>{{$t('业绩排行')}}</div>
    </div>
    <div class="common-title-r">
      <van-popover
        v-model="showPopover1"
        trigger="click"
        placement="bottom-end"
        :actions="roleList"
        class="popoverBox">
        <div class="van-popover__content">
          <template v-for="(item, index) in roleList">
            <div :key="index" class="van-popover__action" @click.stop="changeRole(item)">
              <div class="van-popover__action-text van-hairline--bottom">
                {{ item.text }}
              </div>
            </div>
          </template>
        </div>
        <template #reference>
          <span class="textShow">
            {{ roleShow }}
            <van-icon name="play" />
          </span>
        </template>
      </van-popover>
      <van-popover
        v-model="showPopover2"
        trigger="click"
        placement="bottom-end"
        :actions="rangeList"
        class="popoverBox">
        <div class="van-popover__content">
          <template v-for="(item, index) in rangeList">
            <div :key="index" class="van-popover__action" @click.stop="changeRange(item)">
              <div class="van-popover__action-text van-hairline--bottom">
                {{ item.text }}
              </div>
            </div>
          </template>
        </div>
        <template #reference>
          <span class="textShow">
            {{ rangeShow }}
            <van-icon name="play" />
          </span>
        </template>
      </van-popover>
    </div>
  </div>
  <van-tabs v-model="activeTab" :ellipsis="false">
    <van-tab v-for="(item, index) in tabList" :key="index" :title="item.title" :name="item.value">
      <div class="rankContent">
        <div v-for="data in dataList" :key="data.userId" :class="['rankItem', (data.empId === userInfo.id || data.empName === userInfo.name + $t('组')) ? 'active' : '']">
          <div class="ranking">
            <img v-if="[1,2,3].includes(data.rank)" :src="getUrl[data.rank-1]">
            <div v-else>{{ data.rank }}</div>
          </div>
          <div class="name">
            {{ data.empName }}
            <!-- {{ data.empId == userInfo.id ? $t('我') : data.empName }} -->
          </div>
          <div class="count">{{ data.count }}</div>
        </div>
        <div v-if="dataListAll.length > 10" class="more" @click="onShow">
          {{ showMore ? $t('收起') : $t('展开') }}
          <van-icon v-if="showMore" name="arrow-up" />
          <van-icon v-else name="arrow-down" />
        </div>
        <div v-if="query && dataList.length == 0" class="common-noData" style="height: 212px;">{{$t('暂无数据!')}}</div>
      </div>
    </van-tab>
  </van-tabs>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ROLES } from '@/constants/constants'
import goldIcon from '@/images/gold_icon.png'
import silverIcon from '@/images/silver_icon.png'
import bronzeIcon from '@/images/bronze_icon.png'
import dayjs from 'dayjs'
import salesAssistantService from '@/services/salesAssistant'
import loading from '@/utils/loading'
export default {
  props: {
    rolesInfo: {
      type: [Object, String],
      default: {}
    }
  },
  data() {
    return {
      goldIcon,
      silverIcon,
      bronzeIcon,
      rangeActive: 'month', // 默认本月
      query: false,
      // roleList: [
      //   { value: 1, text: '销售顾问' },
      //   { value: 2, text: '销售组' },
      // ],
      rangeList: [
        { value: 'today', text: this.$t('今日') },
        { value: 'week', text: this.$t('本周') },
        { value: 'month', text: this.$t('本月') },
      ],
      showPopover1: false,
      showPopover2: false,
      tabList: [
        { value: 'RANK_LEAD', title: this.$t('有效线索量') },
        { value: 'RANK_DRIVE', title: this.$t('试驾量') },
        { value: 'RANK_ORDER', title: this.$t('订单量') },
        { value: 'RANK_DELIVERY', title: this.$t('交付量') },
      ],
      activeTab: 'RANK_ORDER',
      dataList: [],
      dataListAll: [],
      getUrl:[goldIcon,silverIcon,bronzeIcon],
      showMore: false,
      formData: {
        dealerId: '',
        rankType: 1,
        statCode: 'RANK_ORDER',
        roleCode: '',
        statisticsTime: {
          start: dayjs().startOf('month').format('YYYY-MM-DD 00:00:00'),
          end: dayjs().endOf('month').format('YYYY-MM-DD 23:59:59'),
        },
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    roleList() {
      let arr = [{ value: 1, text: this.$t('销售顾问') },{ value: 2, text: this.$t('销售组') }]
      const { roleLogos=[] } = this.userInfo
      if (!roleLogos.some((item) => { return [ROLES.SHOWOWNER, ROLES.MASTER].includes(item) })) {
        arr.pop()
      }
      return arr
    },
    roleShow() {
      return this.roleList.find(({ value }) => value===this.formData.rankType)?.text
    },
    rangeShow() {
      return this.rangeList.find(({ value }) => value===this.rangeActive)?.text
    }
  },
  watch: {
    rolesInfo: {
      handler(val) {
        if (val && val.roleCode) {
          this.formData.dealerId = val.dealerId
          this.formData.roleCode = val.roleCode
          this.getRankInfo()
        }
      },
      immediate: true
    },
    activeTab: {
      handler(val) {
        this.formData.statCode = val
        this.getRankInfo()
      }
    }
  },
  methods: {
    // 获取排行榜数据
    async getRankInfo() {
      loading.showLoading()
      const result = await salesAssistantService.rankInfo(this.formData)
      this.query = true
      this.showMore = false
      this.dataList = []
      this.dataListAll = []
      const { code, data=[], msg } = result
      if (code === 0) {
        this.dataListAll = data
        if (data.length > 10) {
          this.dataList = data.slice(0,10)
        } else {
          this.dataList = data
        }
      } else {
        this.$toast(msg)
      }
      loading.hideLoading()
    },
    // 改变角色
    changeRole(item) {
      this.formData.rankType = item.value
      this.showPopover1 = false
      this.getRankInfo()
    },
    // 计算当前周
    getCurrentWeek() {
      const start = this.$moment()
        .isoWeekday(1)
        .format('YYYY-MM-DD 00:00:00') // 本周一
      const end = this.$moment()
        .isoWeekday(7)
        .format('YYYY-MM-DD 23:59:59') // 本周日
      this.formData.statisticsTime = {
        start,
        end,
      }
    },
    // 计算时间
    onDateShortcut(type, load = true) {
      this.dataText = type
      if (type == 'today') {
        this.formData.statisticsTime = {
          start: dayjs().format('YYYY-MM-DD 00:00:00'),
          end: dayjs().format('YYYY-MM-DD 23:59:59'),
        }
      } else if (type == 'week') {
        this.getCurrentWeek()
      } else {
        const start = this.$moment()
          .startOf(type)
          .format('YYYY-MM-DD 00:00:00')
        const end = this.$moment()
          .endOf(type)
          .format('YYYY-MM-DD 23:59:59')
        this.formData.statisticsTime = {
          start,
          end,
        }
      }
      load && this.getRankInfo()
    },
    // 改变周期
    changeRange(item) {
      this.rangeActive = item.value
      this.onDateShortcut(item.value)
      this.showPopover2 = false
    },
    onShow() {
      if (!this.showMore) {
        this.dataList = this.dataListAll
      } else {
        this.dataList = this.dataListAll.slice(0,10)
      }
      this.showMore = !this.showMore
    }
  }
}
</script>
<style lang="less" scoped>
@import "./common.less";
.performanceRank{
  padding: 10px;
  height: auto;
  /deep/ .van-dropdown-menu {
    .van-dropdown-menu__bar{
      height: 22px;
      background: none;
      box-shadow: none;
      .van-dropdown-menu__item{
        justify-content: flex-start;
      }
      .van-dropdown-menu__title{
        font-size: 13px;
      }
      .van-dropdown-menu__title::after{
        border-color: transparent transparent @black @black
      }
    }
  }
  /deep/ .popoverBox{
    &:not(:last-child){
      margin-right: 8px;
    }
    .textShow{
      width: fit-content;
      .van-icon-play{
        transform: rotate(90deg);
      }
    }
  }
  /deep/.rankContent{
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 212px;
    .rankItem{
      display: flex;
      font-size: 13px;
      height: 40px;
      padding: 8px 12px;
      margin-bottom: 8px;
      align-items: center;
      &:first-of-type {
        margin-top: 16px;
      }
      &.active{
        background: rgba(238,212,132,0.1);
        border-radius: 4px;
        border: 1px solid rgba(238,212,132,0.45);
      }
      .ranking{
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 50px;
        img{
          width: 24px;
          height: 24px;
        }
      }
      .name{
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      } 
      .count{
        width: 80px;
        text-align: right;
      }
    }
    .more{
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(13,23,26,0.45);
      font-size: 12px;
    }
  }
}
/deep/ .van-popover__action{
  width: fit-content;
}
</style>