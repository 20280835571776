<template>
  <div :key="key" class="salesAssistant">
    <!-- 头部搜索栏 -->
    <Header :refreshTime="refreshTime" @changeRole="changeRole" @refresh="onRefresh"></Header>
    <van-pull-refresh v-model="isLoading" class="salesAssistant-c" @refresh="onRefresh">
      <div ref="salesAssistant-c" >
        <!-- 方案 -->
        <Programme :rolesInfo="rolesInfo"></Programme>
        <!-- 常用功能 -->
        <CommmonFunction ref="commmonFunction" :rolesInfo="rolesInfo"></CommmonFunction>
        <!-- 代办事项 -->
        <ToDo id="todo" ref="todo" :rolesInfo="rolesInfo" :fromAssistantToDo="fromAssistantToDo" :toDoTime="toDoTime"></ToDo>
        <!-- 业绩排行 -->
        <PerformanceRank v-if="showRank" :rolesInfo="rolesInfo"></PerformanceRank>
      </div>
    </van-pull-refresh>
    <img :src="addIcon" class="add_icon" @click="showPopover = true"/>
    <van-popup v-model="showPopover" round position="bottom">
      <div class="operateBox">
        <div v-for="(item, index) in operateList" :key="index" class="operate-i">
          <img :src="item.icon" @click.stop="operate(item)"/>
          {{ item.text }}
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ROLES } from '@/constants/constants'
import Header from './components/Header.vue'
import Programme from './components/Programme.vue'
import CommmonFunction from './components/CommmonFunction.vue'
import PerformanceRank from './components/PerformanceRank.vue'
import ToDo from './components/ToDo.vue'
import homeIcon from '@/images/home_icon.png'
import userIcon from '@/images/user_icon.png'
import stagingIcon from '@/images/staging_icon.png'
import addIcon from '@/images/add_icon.png'
import xxIcon from '@/images/xx_icon.png'
import pcIcon from '@/images/pc_icon.png'
import sjIcon from '@/images/sj_icon.png'
import dayjs from 'dayjs'
const aaa = '80px'
export default {
  name: 'SalesAssistant',
  components: {
    Header,
    Programme,
    CommmonFunction,
    ToDo,
    PerformanceRank,
  },
  data() {
    return {
      homeIcon,
      userIcon,
      stagingIcon,
      addIcon,
      xxIcon,
      pcIcon,
      sjIcon,
      rolesInfo: '',
      showPopover: false,
      // operateList: [
      //   { text: this.$t('新建线索'), icon: xxIcon },
      //   { text: this.$t('新建排程'), icon: pcIcon },
      //   { text: this.$t('当场试驾'), icon: sjIcon },
      // ],
      fromAssistantToDo: false,
      toDoTime: '',
      isLoading: false,
      key: '',
      refreshTime: '',
      timerId: ''
    }
  },
  beforeRouteEnter(to, from, next){
    next(vm => {
      if (from.path === '/todo-list') {
        vm.fromAssistantToDo = true
        vm.toDoTime = from.query.start
      }
    })
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    showRank() {
      // 当角色包含店长 顾问 组长时展示
      const { roleLogos=[] } = this.userInfo
      return roleLogos.some((item) => { return [ROLES.SALES, ROLES.SHOWOWNER, ROLES.MASTER,ROLES.BUDDY].includes(item) })
    },
    operateList() {
      const { roleLogos=[] } = this.userInfo
      let list = [
        { text: this.$t('新建线索'), icon: xxIcon },
        { text: this.$t('新建排程'), icon: pcIcon },
        { text: this.$t('当场试驾'), icon: sjIcon },
      ]
      if (roleLogos.length == 1 && roleLogos[0] == '1014001' ) {
        list = list.splice(0,2)
      }
      return list
    },
  },
  mounted() {
    this.refreshTime = dayjs().format('HH:mm')
    // 隔 5 分钟自动刷新页面
    this.timerId = setInterval(() => {
      this.onRefresh()
    }, 5*60*1000)
  },
  beforeDestroy(){
    clearInterval(this.timerId)
  },
  methods: {
    onRefresh(){
      this.refreshTime = dayjs().format('HH:mm')
      this.isLoading = true
      this.key = String(Math.ceil(Math.random()*10000))
      this.isLoading = false
    },
    // 切换角色
    changeRole(data) {
      this.rolesInfo = data
    },
    operate(item) {
      this.showPopover = false
      if (item.text == this.$t('新建线索')) {
        this.$router.push({
          path: '/create-clue'
        })
      } else if (item.text == this.$t('新建排程')) {
        this.$router.push({
          path: '/testDriveSchedule'
        })
      } else {
        let appid = 'wxdafaf0480578a1b3'
        if (process.env.VUE_APP_ENV !== 'uat') appid = 'wx25e0b11c7aaf8139'
        if (!appid) return false
        window.wx.invoke('launchMiniprogram', {
          'appid': appid,
          'path': 'pages/index/index?dealerId=' + this.$store.state.app.selectedShopId
        }, (res) => {
          if (res.err_msg === 'launchMiniprogram:ok'){
            console.log(this.$t('跳转成功'))
          } else {
            this.$toast.fail(this.$t('无法跳转'))
          }
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.salesAssistant{
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: 96px;
  display: flex;
  flex-direction: column;
  color: @black;
  position: relative;
  box-sizing: border-box;
  height: 100vh;
  position: relative;
  .salesAssistant-c{
    flex: 1;
    overflow-y: scroll;
    padding: 12px 16px;
    /deep/ .van-pull-refresh__track{
      height: auto;
    }
  }
  .common-block:not(:last-of-type){
    margin-bottom: 16px;
  }
  .common-footer{
    display: flex;
    .tabBar{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      .home{
        color: @yellow-text;
      }
      img{
        width: 22px;
        height: 22px;
        margin-bottom: 4px;
      }
    }
  }
  .add_icon{
    position: absolute;
    right: 16px;
    bottom: 120px;
    width: 46px;
    height: 46px;
  }
  .operateBox{
    height: 124px;
    display: flex;
    .operate-i{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img{
        width: 38px;
        height: 38px;
        margin-bottom: 8px;
      }
    }
  }
}
</style>