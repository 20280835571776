<template>
<div class="header">
  <!-- <div class="name">{{ userInfo.name }}</div> -->
  <div class="roles">
    <van-dropdown-menu v-if="roleList.length > 1" active-color="#B9921A">
      <van-dropdown-item v-model="roleActive" clearable :options="roleList">
        <template #title>
          <p class="dealerInfo">{{ dealerName }}</p>
          <p class="dealerInfo">{{ userInfo.name }}：{{ selectedRoleName }}</p>
        </template>
      </van-dropdown-item>
    </van-dropdown-menu>
    <div v-else style="padding-left: 8px;">
      <p class="dealerInfo">{{ dealerName }}</p>
      <p class="dealerInfo">{{ userInfo.name }}：{{ selectedRoleName }}</p>
    </div>
  </div>
  <!-- 手动刷新 -->
  <!-- <div class="refresh">
    <span style="margin-right: 4px;">{{ refreshTime }}</span>
    <van-icon name="replay" size="18" style="cursor: pointer;" @click="$emit('refresh')"/>
  </div> -->
  <div class="icons">
    <img class="img" :src="searchIcon" @click="search"/>
    <!-- <van-image class="img remind" :src="remindIcon" @click="goMsg">
      <span v-if="remindList.length > 0"></span>
    </van-image> -->
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import remindIcon from '@/images/remind_icon.svg'
import searchIcon from '@/images/search_icon.svg'
import { ROLES } from '@/constants/constants'
import store from '@/store'
export default {
  props:{
    refreshTime:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      remindIcon,
      searchIcon,
      roleActive: '',
      searchStr: '',
      selectedRoleName: '',
      remindList: [1]
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash', 'selectedShopId']),
    dealerName() {
      const { dealers=[] } = this.userInfo
      return this.selectedShopId ? dealers.find(({ id }) => { return id === this.selectedShopId })?.name : dealers[0]?.name
    },
    roleList() {
      const roles = (this.dictHash ||[])[1014 ] || []
      if (roles.length == 0 ) return []
      let arr = []
      const { dealers=[] } = this.userInfo
      dealers.forEach(item => {
        let { roleLogos=[] } = item
        roleLogos = roleLogos.filter((value) => { return [ROLES.SHOWOWNER,ROLES.MASTER,ROLES.BUDDY,ROLES.DCC].includes(value) })
        const sortList = [ROLES.SHOWOWNER,ROLES.MASTER,ROLES.BUDDY,ROLES.DCC]
        roleLogos = roleLogos.sort((a,b) => {
          return sortList.indexOf(a) - sortList.indexOf(b)
        })
        arr = arr.concat(
          roleLogos.map((roleItem) => {
            return {
              value: `${item.id}_${roleItem}`,
              roleName: roles.find(({ code }) => code === roleItem)?.name,
              dealerName: item.name,
              text: `${item.name}-${roles.find(({ code }) => code === roleItem)?.name}`,
            }
          })
        )
      })
      return arr
    },
  },
  watch: {
    roleList: {
      handler(val){
        if (Array.isArray(val) && val.length > 0) {
          this.roleActive = sessionStorage.getItem('salesAssistant_checkedRole') || val[0].value
        }
      },
      immediate: true
    },
    roleActive: {
      handler(val) {
        const roles = (this.dictHash ||[])[1014 ] || []
        sessionStorage.setItem('salesAssistant_checkedRole', val)
        const dealerId = val.split('_')[0]
        const roleCode = val.split('_')[1]
        store.commit('app/setSelectedShopId', dealerId)
        this.$nextTick(() => {
          this.$emit('changeRole', { dealerId, roleCode })
          this.selectedRoleName = roles.find(({ code }) => code === roleCode)?.name
        })
      },
      immediate: true
    },
  },
  mounted() {
  },
  methods: {
    // 点击搜索
    search() {
      this.$router.push({
        path: '/shop-clue-search'
      })
    },
    goMsg() {
      this.$toast(this.$t('功能开发中 敬请期待！'))
    }
  },
}
</script>
<style lang="less" scoped>
.header{
  display: flex;
  justify-content: space-between;
  height: 48px;
  align-items: center;
  background: #fff;
  padding: 0 16px;
  // position: sticky;
  // top: 0;
  z-index: 10;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  .name {
    min-width: 40px;
    max-width: 68px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 500;
  }
  .refresh{
    display: flex;
    align-items: center;
    margin-right: 8px;
    font-size: 14px;
  }
  .roles{
    flex: 1;
    height: 48px;
    display: flex;
    align-items: center;
    /deep/ .van-dropdown-menu {
      height: 48px;
      display: flex;
      align-items: center;
      .van-dropdown-menu__bar{
        height: 48px;
        background: none;
        box-shadow: none;
        .van-dropdown-menu__item{
          justify-content: flex-start;
        }
        .van-dropdown-menu__title{
          font-size: 13px;
        }
        .van-dropdown-menu__title::after{
          border-color: transparent transparent @black @black
        } 
      }
    }
    /deep/.van-dropdown-item{
      .van-cell__title{
        flex: 2 !important;
      }
      .van-cell__value{
        flex: 1 !important;
      }
    }
  }
  .icons{
    max-width: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .img{
      width: 22px;
      position: relative;
      &.remind{
        width: 24px;
      }
      span{
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: #E4002C;
        position: absolute;
        right: 2px;
        top: 2px;
        // margin-top: -4px;
        // margin-right: -4px;
      }
    }
  }
}
</style>