<!-- 待办事项 -->
<template>
<div ref="todo" class="toDo common-block">
  <div class="common-title-n">
    <div class="common-title-l">
      <img src="@/images/toDo_icon.png"/>
      <div>{{$t('待办事项')}}</div>
    </div>
    <div class="common-title-r">
      <img :src="calendarTodayIcon" style="width: 20px; height: 20px" @click.stop="backToday" >
      <img :src="calendarIcon" @click.stop="showCalendar"/>
    </div>
  </div>
  <div class="my-calendar">
    <WeekdayCalendar v-if="formData.dealerId" ref="weekdayCalendar" :isScroll="true" :dealerId="formData.dealerId" :rolesInfo="rolesInfo" :roleCode=formData.roleCode :init-date="getWeekdayDate" @change="changeTime" />
  </div>
  <!-- 代办数据项 -->
  <div ref="todoData" class="todoData">
    <div v-for="(item, index) in toDoList" :key="index" class="clue item">
      <div class="title">
        {{ item.groupName }}
        <div class="triangle"></div>
      </div>
      <div class="content">
        <div v-for="term in item.list" :key="term.code" class="term" @click="goToDoList(term)">
          <div class="term-name">{{ term.name }}</div>
          <div class="term-count">{{ term.count }}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- 日历弹窗 -->
  <van-popup v-model="showPopup" position="top" closeable :style="{ height: '70%' }">
    <CalendarVue v-if="showPopup" ref="calendarvue" maxHeight="100%" :isRemove="false" :init-date="getWeekdayDate" @change="changeDate"/>
  </van-popup>
</div>
</template>
<script>
import dayjs from 'dayjs'
import salesAssistantService from '@/services/salesAssistant'
import loading from '@/utils/loading'
import calendarIcon from '@/images/calendar.svg'
import calendarTodayIcon from '@/images/calendar_today.svg'
import WeekdayCalendar from './WeekdayCalendar.vue'
import CalendarVue from './Calendar.vue'
export default {
  components: {
    WeekdayCalendar,
    CalendarVue
  },
  props: {
    rolesInfo: {
      type: [Object, String],
      default: {}
    },
    fromAssistantToDo: {
      type: Boolean,
      default: false,
    },
    toDoTime: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      calendarIcon,
      calendarTodayIcon,
      showPopup: false,
      tableType: 'ALL',
      refName: 'calendar-i',
      toDoList: [],
      formData: {
        clientType: 2,
        dealerId: '',
        roleCode: '',
        planTime: {
          start: dayjs().format('YYYY-MM-DD 00:00:00'),
          end: dayjs().format('YYYY-MM-DD 23:59:59')
        }
      }
    }
  },
  computed: {
    getWeekdayDate() {
      return this.formData.planTime.start
    }
  },
  watch: {
    rolesInfo: {
      handler(val) {
        if (val && val.roleCode) {
          this.formData.dealerId = val.dealerId
          this.formData.roleCode = val.roleCode
          this.getToDoList()
          this.$nextTick(() => {
            // this.$refs.weekdayCalendar.getToDoCalendar()
          })
        }
      },
      immediate: true
    },
    fromAssistantToDo: {
      handler(val) {
        if (val) {
          if (this.toDoTime) {
            this.formData.planTime.start = dayjs(this.toDoTime).format('YYYY-MM-DD 00:00:00')
            this.formData.planTime.end = dayjs(this.toDoTime).format('YYYY-MM-DD 23:59:59')
          }
          this.$nextTick(() => {
            const scrollHeight = this.$refs.todo.offsetTop
            this.$refs.todo.parentElement.scrollTo(0, scrollHeight - 16)
          })
        }
      },
      immediate: true
    }
  },
  mounted() {
  },
  methods: {
    // 获取代办列表数据
    async getToDoList() {
      loading.showLoading()
      const result = await salesAssistantService.todoList(this.formData)
      loading.hideLoading()
      this.toDoList = []
      const { code, data, msg } = result
      if (code === 0) {
        data.forEach(item => {
          const findIndex = this.toDoList.findIndex((ex) => { return ex.groupName === item.groupName })
          if (findIndex == -1) {
            const list = []
            list.push(item)
            const obj = { groupName: item.groupName, list }
            this.toDoList.push(obj)
          } else {
            this.toDoList[findIndex]?.list.push(item)
          }
        })
      } else {
        this.$toast(msg)
      }
    },
    // 改变时间
    changeTime([start,end]) {
      this.formData.planTime.start =start
      this.formData.planTime.end = end
      this.getToDoList()
    },
    // 改变日历选择
    changeDate(date) {
      this.showPopup = false
      this.formData.planTime.start = dayjs(date).format('YYYY-MM-DD 00:00:00'),
      this.formData.planTime.end = dayjs(date).format('YYYY-MM-DD 23:59:59')
      this.getToDoList()
    },
    // 跳转至代办列表
    goToDoList({ code }) {
      this.$router.push({
        path: '/todo-list',
        query: {
          taskCode: code,
          start: dayjs(this.formData.planTime.start).format('YYYY-MM-DD'),
          end: dayjs(this.formData.planTime.end).format('YYYY-MM-DD'),
          roleCode: this.formData.roleCode
        }
      })
    },
    // 展示日历数据
    showCalendar() {
      this.showPopup = true
      const { dealerId, roleCode } = this.formData
      this.$nextTick(() => {
        this.$refs.calendarvue.initCalendar({ dealerId, roleCode })
      })
    },
    // 返回今天
    backToday() {
      this.formData.planTime.start = dayjs().format('YYYY-MM-DD 00:00:00')
    },
    // 刷新列表和日历上的数据
    onRefresh(){
      this.getToDoList()
      this.$nextTick(() => {
        this.$refs.weekdayCalendar.getToDoCalendar()
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import "./common.less";
.toDo{
  display: flex;
  flex-direction: column;
  padding: 10px;
  .common-title-r{
    display: flex;
    align-items: center;
    img{
      width: 24px;
      height: 24px;
      margin-left: 8px
    }
  }
  .my-calendar{
    margin: 12px 0;
    /deep/ .van-calendar__body{
      overflow: hidden;
    }
    /deep/.dateList{
      .calendarBox {
        .calendar-i {
          .calendar-i-c{
            .today{
              font-size: 13px !important;
            }
          }
        }
      }
    }
  }
  .todoData{
    display: flex;
    flex-direction: column;
    .item{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 13px;
      box-sizing: border-box;
      border-radius: 4px;
      background: #F3F3F3;
      &:not(:last-of-type) {
        margin-bottom: 12px;
      }
      .title{
        font-size: 14px;
        height: 20px;
        line-height: 20px;
        width: fit-content;
        padding-left: 12px;
        background: #EED484;
        position: relative;
        .triangle{
          width: 20px;
          height: 20px;
          background: #EED484;
          // border-left: 10px solid transparent;
          // border-right: 10px solid transparent;
          // border-bottom: 20px solid #EED484;
          // transform: rotate(90deg);
          clip-path: polygon(0 0, 0 100%, 100% 100%);
          transform: rotate(90deg);
          position: absolute;
          right: -20px;
          top: 0;
        }
      }
      .content{
        display: flex;
        min-height: 49px;
        padding: 10px 0;
        padding-bottom: 0px;
        flex-wrap: wrap;
        .term{
          display: flex;
          flex-direction: column;
          width: 25%;
          justify-content: space-between;
          align-items: center;
          position: relative;
          cursor: pointer;
          padding: 0 4px;
          padding-bottom: 10px;
          &:not(:last-of-type)::after{
            position: absolute;
            right: 0px;
            top: 15%;
            content: ' ';
            height: 70%;
            width: 1px;
            background: rgba(13,23,26,0.05);
            border-radius: 0px 0px 0px 0px;
          }
          .term-name{
            min-height: 22px;
            font-size: 12px;
            color: rgba(13,23,26,0.75);
            flex: 1;
            text-align: center;
          }
          .term-count{
            height: 20px;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>