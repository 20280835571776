import request from '@/utils/request'
import SERVER from './server'
import { thenResolve } from '@/utils/index'

// 分组查询顾问
export const listSalesGroup = async (params) => thenResolve(await request.get(`${SERVER.ADMIN_SERVICE}/api/v1/employee/qw/listSalesGroup`, { params }))

export const transfer = async (params) => thenResolve(await request.post(`${SERVER.STATISTICS_SERVICE}/api/v1/statistics/transfer`, params))

export const statisticsKpi = async (params) => thenResolve(await request.post(`${SERVER.STATISTICS_SERVICE}/api/v1/statistics/kpi`, params))
