<template>
<div class="commmonFunction common-block">
  <div class="common-title-n">
    <div class="common-title-l">
      <img src="@/images/commonFunction_icon.png"/>
      <div>{{$t('常用功能')}}</div>
    </div>
    <div class="common-title-r">
      <van-icon name="setting-o" size="20" color="#B9921A" @click="setting"/>
    </div>
  </div>
  <div class="module">
    <div v-for="(item, index) in moduleList" :key="index" class="module-i">
      <div class="image">
        <img :src="item.iconUrl" @click="goApplication(item)"/>
        <div v-if="appToDo[item.code]" class="appToDo">{{ appToDo[item.code] > 99 ? '99+' : appToDo[item.code] }}</div>
      </div>
      <div class="name">{{ item.name }}</div>
    </div>
  </div>
</div>
</template>
<script>
import salesAssistantService from '@/services/salesAssistant'
import { isUrl } from '@/utils'
import { mapGetters } from 'vuex'
export default {
  props: {
    rolesInfo: {
      type: [Object, String],
      default: {}
    }
  },
  data() {
    return {
      moduleList: [],
      appToDo: {}
    }
  },
  computed: {
    ...mapGetters([ 'selectedShopId']),
  },
  watch: {
    // 监听到店铺信息发生变化 
    selectedShopId: {
      handler(val, oldVal) {
        if (val && oldVal && val !== oldVal) {
          this.getModule()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getModule()
  },
  methods: {
    async getToDo(params) {
      const result = await salesAssistantService.appTodo(params)
      this.$set(this, 'appToDo', result)
    },
    // 获取当前设置模块
    async getModule() {
      const result = await salesAssistantService.commonEmpResource()
      this.getToDo(Array.isArray(result) ? result.map(({ code }) => { return code }) : [])
      this.moduleList = result || []
    },
    // 设置
    setting() {
      this.$router.push({
        path: '/salesAssistant/staging',
        query: {
          setMark: true
        }
      })
    },
    // 跳转应用
    async goApplication({ code, resourcesUri }) {
      const env = process.env.VUE_APP_ENV
      if ((env === 'dev' && code === '1000009') || (env === 'sit' && code === '1000015') || (env === 'uat' && code === '1000011') || (env === 'pro' && code === '1000045')) {
      // 试驾伴侣 跳转至小程序
        let appid = 'wxdafaf0480578a1b3'
        if (env !== 'uat') appid = 'wx25e0b11c7aaf8139'
        if (!appid) return false
        window.wx.invoke('launchMiniprogram', {
          'appid': appid,
          'path': 'pages/index/index?dealerId=' + this.$store.state.app.selectedShopId
        }, (res) => {
          if (res.err_msg === 'launchMiniprogram:ok'){
            console.log(this.$t('跳转成功'))
          } else {
            this.$toast.fail(this.$t('无法跳转'))
          }
        })
      } else if (isUrl(resourcesUri)){
        // 外部链接 直接跳转
        window.location.href = resourcesUri
      } else if (resourcesUri) {
        this.$router.push({
          path: resourcesUri,
        })
      } else {
        this.$toast(this.$t('功能开发中 敬请期待！'))
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import "./common.less";
.commmonFunction{
  padding: 10px;
  .module{
    display: flex;
    align-items: stretch;
    height: auto;
    margin-top: 12px;
    .module-i{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      min-height: 63px;
      font-size: 12px;
      flex: 1;
      .image{
        width: 36px;
        height: 36px;
        margin-bottom: 8px;
        position: relative;
        .appToDo{
          position: absolute;
          right: 0;
          top: 0;
          width: 22px;
          height: 18px;
          border-radius: 18px;
          background: red;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: -8px;
          margin-top: -8px;
          color: #ffffff;
          font-size: 10px;
        }
      }
      img{
        width: 38px;
        height: 38px;
      }
      .name{
        width: 100%;
        text-align: center;
        margin-top: 8px;
        overflow: hidden;
        flex: 1;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
